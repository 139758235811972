@use 'sass:math';

$blue: #9084fb;
$darkBlue: #5749d8;
$lightBlue: #eef5ff;

$green: #cfc;
$lightGreen: #dfd;
$darkGreen: #0d0;

$yellow: #ffc;
$darkYellow: #a80;

$lightRed: #fdd;
$red: #fdd;
$darkRed: #f00;

// Strong short shadow and long tail
$shadow: 1px 1px 4px -2px rgba(0, 0, 0, 0.2),
  4px 8px 20px -2px rgba(0, 0, 0, 0.1);
$lightShadow: 1px 1px 0px -2px rgba(0, 0, 0, 0.2),
  1px 2px 10px -2px rgba(0, 0, 0, 0.1);

$radius: 8px;
$space: 20px;

@import "../node_modules/picnic/src/picnic.scss";
@import "../node_modules/prismjs/themes/prism-solarizedlight.css";
@import "./table.scss";

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("/fonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
  font-display: Arial, sans-serif;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("/fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format("woff2");
}

$width: 1200px;

body {
  font-family: Montserrat, sans-serif, sans;
  padding: $space;
  height: auto;
  max-width: $width;
  margin: 50px auto;
  background: #f1f1ff;
  font-size: 18px;
  transition: all 0s ease;

  @media (min-width: 600px) {
    margin: 50px auto 40vh;
  }
}

nav {
  z-index: 1000;
  padding: 0 20px;

  @media (min-width: 960px) {
    .menu {
      display: flex;
    }
  }

  @media (min-width: 1200px) {
    padding: 0 calc(50vw - #{math.div($width, 2)} + 20px);
  }

  .brand {
    color: #aaa;
    padding: 0;

    a {
      color: inherit;
      &:last-child {
        color: $blue;
      }
      &:hover {
        color: $blue;
      }
    }
  }

  .burger {
    padding: 0;
    i {
      padding: 0 10px;
      font-size: 26px;
      line-height: 36px;
      width: auto;
      height: auto;
    }
  }

  .button.active {
    box-shadow: none;
  }

  a.button:last-child {
    margin-right: 0;
  }

  @media (max-width: 350px) {
    a.button {
      margin-right: 5px;
    }
  }
}

a {
  cursor: pointer;

  &.pseudo.button {
    color: $darkBlue;
  }
}

[readonly] {
  background: #f2f2f2;
  &:focus {
    border-color: #ccc;
  }
}

p:last-child {
  margin-bottom: 0;
}

.Dashboard {
  .flex {
    align-items: stretch;
    .model {
      height: 100%;
    }
  }

  .pseudo.button.strong {
    color: #fff;
    background: $blue;
  }

  .card > * {
    display: flex;
    align-items: center;
  }

  .card > article {
    display: block;
  }

  .add {
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 20px;
    border: 1px solid $blue;
    background: #fff;
    color: $blue;
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 0 5px;

    &:hover,
    &:focus {
      background: $blue;
      color: #fff;
    }
  }

  .relative {
    position: relative;
  }

  .model {
    input {
      display: block;
      font-size: 14px;
      height: 30px;
      width: 100%;

      &.error {
        border-color: $darkRed;
        box-shadow: none;
      }
    }

    label.error,
    a.error {
      display: block;
      font-size: 14px;
      line-height: 18px;
      padding: 2px 0;
      color: $darkRed;
      i {
        font-size: 16px;
        width: 14px;
        height: 14px;
        line-height: 14px;
        vertical-align: sub;
      }
    }
  }

  select.type {
    color: #333;
    background: none;
    font-size: 14px;
    font-family: monospace;
    font-weight: normal;
    position: absolute;
    right: 3px;
    top: 4px;
    min-width: 80px;
    width: auto;
    padding: 0 2px;
    border: 1px solid #ddd;
    line-height: 20px;
    height: 22px;
    border-radius: 22px;
    text-align: center;

    &:focus {
      border-color: $blue;
    }
  }

  .missing {
    text-align: center;
    margin: 30px 0 80px;
  }
}

.actions {
  text-align: right;
  justify-content: flex-end;
  margin-bottom: -$space;
}

section {
  margin-top: 2 * $space;
}

@media (max-width: 900px) {
  section:first-child {
    margin-top: 0;
  }
}

h1,
h2,
h3 {
  padding: 0;
  line-height: 25px;
  font-size: 1.6em;
}

h1 {
  line-height: 30px;
  font-size: 25px;
  @media (min-width: 900px) {
    .flex {
      margin-left: 0;
      display: inline;
    }
    .flex > * {
      width: auto;
      padding: 0;
      display: inline;
    }
  }
}

h2 {
  font-size: 1.2em;
}

h3 {
  font-size: 1em;
}

.modal .pseudo.dangerous {
  background: none;
}

.nogrow {
  flex-grow: 0;

  .pseudo.button {
    background: #fff;
    font-size: 0.8em;
    margin: 0;
    border-radius: 50px;
    box-shadow: $shadow;

    &:hover {
      box-shadow: $lightShadow;
    }
  }
}

.Switch > * {
  display: block !important;
}

.card {
  margin-bottom: 0;
  border: none;
  border-radius: $radius;
  box-shadow: $shadow;
  display: block;
  padding: 20px;
  color: inherit;

  @media (max-width: 900px) {
    margin: 0 #{-$space};
    border-radius: 0;
  }

  .defaults {
    margin-top: math.div($space, 2);
  }

  .rps {
    font-size: 10px;
    background: $green;
    border-radius: 100px;
    font-weight: bold;
    padding: 3px 10px;
    line-height: 20px;

    &.warn {
      background: $yellow;
    }

    &.danger {
      background: $red;
    }
  }
}

.card {
  article {
    p {
      margin: 0.6em 0;
    }
  }
}

a.card {
  cursor: pointer;

  &:hover {
    box-shadow: $lightShadow;
  }
}

code {
  font-family: "Consolas", "Monaco", "Andale Mono", "Ubuntu Mono", monospace;
}

pre,
pre[class*="language-"] {
  background: #f5f5f5;
  color: #3d464a;
  padding: 15px $space;
  height: 100%;
  line-height: 1.2;
  font-size: 0.9rem;
  font-family: "Consolas", "Monaco", "Andale Mono", "Ubuntu Mono", monospace;

  margin: $space #{-$space};
  width: calc(100% + #{$space * 2});
  border-radius: 0;

  &:first-child {
    margin-top: -$space;
  }

  &:last-child {
    margin-bottom: -$space;
  }

  code {
    font-family: inherit;
    font-size: inherit;
    background: inherit;
    color: inherit;
  }

  &::selection,
  ::selection {
    color: #fff;
    background: #9084fb;
  }
}

.token.operator {
  background: none;
}

.card.endpoint {
  padding: 0;
  border: 0;

  @media (max-width: 800px) {
    margin: 0 #{-$space};
    border-radius: 0;
  }

  input {
    padding: 15px 20px;
    height: 50px;
    border: none;
    font-size: inherit;
    background: #fff;
  }
}

.Requests {
  .label {
    font-size: 0.9rem;
    margin-left: 0;
    font-family: monospace;
    background: $lightBlue;
    color: $darkBlue;
    &.ok {
      // background: $lightGreen;
      color: #999;
    }
    &.warn {
      background: $lightGreen;
      color: #999;
    }
    &.error {
      color: $darkRed;
    }
  }

  .cardlist {
    overflow: hidden;
    box-shadow: $shadow;
    margin: 0 #{-$space};

    @media (min-width: 900px) {
      margin: 0;
      border-radius: $radius;
    }

    .card {
      border-radius: 0;
      box-shadow: none;
      border-bottom: 1px solid #eee;
      margin: 0;
      padding: 15px $space;
    }
  }
}

.Data {
  .tably {
    width: calc(100% + #{$space * 2});
    margin: 0 #{-$space};
    overflow-x: auto;
    @media (min-width: 900px) {
      width: 100%;
      margin: 0 0;
    }
  }
  .value {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &::before {
      content: attr(data-field);
      margin-right: 10px;
      font-weight: bold;
      min-width: 20%;
      display: inline-block;
      @media (min-width: 900px) {
        display: none;
      }
    }
  }

  table .label,
  table .button {
    background: $lightBlue;
    color: $darkBlue;
    margin: 0;
    font-family: monospace;
    line-height: 16px;
    font-size: 0.8rem;
    border: 1px solid transparent;

    &:hover {
      // background: $darkBlue;
      border: 1px solid $darkBlue;
    }
  }
}

.card.operations {
  padding: 15px;
}

.card.info {
  max-height: 0;
  overflow: hidden;
  padding: 0 20px;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;

  @media (max-width: 900px) {
    transition: all 0s linear;
  }

  &.show {
    margin-bottom: $space;
    opacity: 1;
    padding: 20px;
    max-height: none;
    transform: scaleY(1);
  }
}

.Loading {
  text-align: center;
  .flex {
    min-height: 300px;
    align-items: center;
  }
}

main {
  margin-bottom: 50vh;
}
