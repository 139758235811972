.card > table {
  display: table;
  width: 100%;
  table-layout: fixed;

  tr {
    background: #fff;
    border-bottom: 1px solid $lightBlue;
  }
  th {
    background: #fff;
    color: $darkBlue;
    font-weight: bold;
    &.time {
      text-align: right;
      padding-right: 20px;
    }
  }
  .response {
    text-align: right;
    time {
      width: 70px;
      text-align: right;
      font-family: monospace;
    }
  }
}

.react-grid-HeaderCell {
  padding: 4px 8px;
}

.Data {
  table {
    width: 100%;
    padding: 20px;

    font-size: 15px;
    background: #fff;
    border-radius: $radius;
    border-collapse: separate;
    border: none;
    table-layout: fixed;

    th {
      color: #000;
    }

    tr,
    th,
    td {
      transition: all 0.3s ease;
      background: transparent;
    }

    tr {
      cursor: pointer;
    }

    tbody tr:hover {
      background: $lightBlue;
    }

    .material-icons {
      margin-left: 2px;
      transition: all 0.3s ease;
    }

    tr.selected {
      background: $lightBlue;
    }

    td {
      border-top: 1px solid $lightBlue;
    }

    th {
      padding: 10px 9px;
    }

    td {
      padding: 0;
    }

    .value,
    input {
      margin: 0;
      padding: 8px;
    }

    input {
      width: calc(100% - 20px);
      border: 1px solid #ddd;
    }

    .value {
      border: none;
      padding: 4px 8px;
    }

    .actions {
      display: flex;
      justify-content: flex-start;
      .fa {
        height: 35px;
        margin: 0;
        margin-left: 5px;
      }
    }

    button {
      background: #eee;
      color: #000;
    }

    @media (max-width: 900px) {
      margin: 0 -20px;
      width: calc(100% + 40px);
      border-radius: 0;

      thead {
        display: none;
      }

      tr {
        display: block;
        margin-bottom: 50px;
      }

      td {
        display: block;
        border: none;
      }
    }
  }
}
